import React from "react";
import { ImageProps, VideoProps } from "../types";
import Image from "next/image";
import VideoPlayer from "@/components/VideoPlayer";

const VerticalImage = ({
  imageurl,
  imagetitle,
  videourl,
  children,
}: ImageProps & VideoProps) => {
  return (
    <div>
      {imageurl ? (
        <>
          {videourl ? (
            <div className="w-full flex items-center justify-center">
              <VideoPlayer
                videoUrl={videourl}
                title={imagetitle}
                videoThumbnail={imageurl}
                videoContainerClassName="w-[750px] h-[420px] flex items-center justify-center"
                videoClassName="w-[343px] md:w-[750px] h-[194px] md:h-[420px]"
              />
            </div>
          ) : (
            <div className="w-full flex items-center">
              <Image
                layout="responsive"
                width={342}
                height={280}
                src={imageurl}
                alt={imagetitle || ""}
                className="block object-cover"
              />
            </div>
          )}
        </>
      ) : null}
      <div>{children}</div>
    </div>
  );
};

export default VerticalImage;
