"use client";
import React, { useState } from "react";
import Image from "next/image";
import SeeMore from "@/components/icons/SeeMore";
import SeeLess from "@/components/icons/SeeLess";
import { ExpandableInstructorListProps } from "../types";
import getInstructorFullName from "@/lib/partner/utils/getInstructorFullName";

const ExpandableInstructorsList: React.FC<ExpandableInstructorListProps> = ({
  instructors,
  preExpandedThreshold = 0,
}) => {
  const isPreExpanded =
    instructors && instructors.length < preExpandedThreshold;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="container mx-auto p-4">
      {!isPreExpanded && (
        <>
          <div className="flex flex-wrap mb-4 ml-[10px]">
            {instructors.map((instructor, index) => (
              <div
                key={index}
                className="w-12 h-12 relative rounded-full -ml-[10px]"
                style={{ border: "2px solid #fff" }}
              >
                <Image
                  src={instructor.imageUrl}
                  alt={getInstructorFullName(instructor) || "Instructor"}
                  title={getInstructorFullName(instructor)}
                  className="rounded-full object-cover"
                  layout="fill"
                  sizes="48px"
                />
              </div>
            ))}
          </div>
          <button
            onClick={toggleExpand}
            className="flex items-center text-lg font-semibold text-gray-800 mb-4 underline"
          >
            See instructor bios{" "}
            <span className="ml-2">
              {isExpanded ? <SeeLess /> : <SeeMore />}
            </span>
          </button>
        </>
      )}

      <div
        className={`transition-all duration-500 ease-in-out ${
          isExpanded || isPreExpanded ? "opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {instructors.map((instructor, index) => (
            <div key={index} className="flex items-start space-x-4">
              <div className="w-12 h-12 relative min-w-12">
                <Image
                  src={instructor.imageUrl}
                  alt={getInstructorFullName(instructor) || "Instructor"}
                  title={getInstructorFullName(instructor)}
                  className="rounded-full object-cover min-w-12"
                  layout="fill"
                  sizes="48px"
                />
              </div>
              <div className="flex-column">
                <a
                  className="text-lg font-bold m-0 underline"
                  href={`${instructor.bioUrl}`}
                >
                  {getInstructorFullName(instructor)}
                </a>
                <p className="text-sm m-0">{instructor.position}</p>
                <p className="text-sm text-gray-600 m-0">
                  {instructor.organizationName}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpandableInstructorsList;
