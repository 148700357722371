import * as React from "react";
import { ImageProps } from "../../types";
import Image from "next/image";
import styles from "./index.module.css";

export default function ImageRightParallelogram({
  children,
  imageurl,
  imagetitle,
}: ImageProps) {
  return (
    <div className="flex flex-col-reverse md:flex-row py-8 mb-4 container items-center">
      <div className={styles["parallelogram-copy"]}>{children}</div>
      <div className="flex justify-center">
        {imageurl ? (
          <div className={`${styles["parallelogram-wrapper"]} media-module`}>
            <div className={styles["parallelogram"]}>
              <Image
                layout="responsive"
                width={342}
                height={280}
                src={imageurl}
                alt={imagetitle || ""}
                className={styles["parallelogram-img"]}
              />
            </div>
            <div className={styles["parallelogram-slant"]}></div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
