import { Sponsor } from "@/lib/course/types";
import Image from "next/image";
import React from "react";

interface SponsorsProps {
  sponsors: Sponsor[];
  containerClassName?: string;
  titleClassName?: string;
}

export default function Sponsors({
  sponsors,
  containerClassName = "",
  titleClassName = "",
}: SponsorsProps) {
  return (
    <div className={containerClassName}>
      <h3 className={titleClassName}>Sponsors</h3>
      {sponsors.map((sponsor: Sponsor) => (
        <div key={sponsor.name} className="flex flex-col md:flex-row gap-4">
          {sponsor.logoImageUrl ? (
            <div className="">
              <Image
                src={sponsor.logoImageUrl}
                alt={sponsor.name || "Sponsor Logo"}
                title={sponsor.name}
                width={170}
                height={85}
                className="max-w-none"
              />
            </div>
          ) : null}
          <p className="break-keep">
            The production of this course would not have been possible without
            the generous contribution of {sponsor.name}.
          </p>
        </div>
      ))}
    </div>
  );
}
