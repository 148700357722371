"use client";
import * as React from "react";
import Image from "next/image";
import { Instructor } from "@/lib/instructor/types";
import Link from "next/link";

interface NewInstructorListProps {
  instructors: Omit<Instructor, "aboutMe">[];
}
const NewInstructorList = ({ instructors }: NewInstructorListProps) => {
  const instructorList = instructors.map((instructor) => {
    return {
      ...instructor,
      organizationName:
        instructor.organizationOverride ||
        instructor.partnerName ||
        instructor.partners?.[0]?.name ||
        "",
    };
  });
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div>
      <div className="flex justify-start align-center mb-1">
        <h2 className="text-2xl text-primary mt-0">Meet the instructors</h2>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {instructorList.map((card, index) => (
          <Link
            key={card.id}
            href={`/bio/${card.slug}`}
            className={`
              min-w-[200px] md:h-[154px] border border-putty-400 p-6 md:p-8 flex flex-col md:flex-row rounded-xl justify-between md:gap-4 bg-white
              ${
                isExpanded
                  ? ""
                  : index >= 4
                    ? "hidden"
                    : index >= 2
                      ? "hidden md:flex"
                      : ""
              }`}
          >
            <div className="flex flex-row gap-4">
              {card.imageUrl ? (
                <div className="flex items-center justify-center min-w-[90px] min-h-[90px] flex-shrink-0 hidden md:block">
                  <Image
                    src={card.imageUrl || ""}
                    alt={card.firstName || ""}
                    title={card.firstName}
                    width={90}
                    height={90}
                    className="w-[90px] h-[90px] object-cover  m-0 rounded-full"
                  />
                </div>
              ) : null}

              <div className="md:h-24 flex gap-2 flex-col justify-center">
                <p className="text-lg font-bold lg:text-base m-0 text-secondary-500">
                  {card.firstName} {card.lastName}
                </p>
                <p className="text-base font-bold md:font-normal lg:text-sm m-0 text-gray-800">
                  {card.position || ""}
                  {card.position && card.organizationName && " at "}
                  {card.organizationName || ""}
                </p>
              </div>
            </div>
            <div className="md:!flex items-center justify-center min-w-[90px] md:min-h-[90px]">
              {card.partners &&
                card.partners[0] &&
                card.partners[0].logoUrl && (
                  <Image
                    src={card.partners[0].logoUrl}
                    alt={card.id || ""}
                    title={card.id}
                    width={90}
                    height={90}
                    layout="responsive"
                    className="max-w-[96px] max-h-[96px] object-cover  m-0 rounded-full"
                  />
                )}
            </div>
          </Link>
        ))}
      </div>
      {(instructorList.length > 4 || instructorList.length > 2) && (
        <div
          className={`
            flex justify-center mt-5
            ${instructorList.length <= 2 ? "hidden" : ""} 
            ${instructorList.length > 4 ? "md:flex" : "md:hidden"}
          `}
        >
          <span
            className="underline cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {!isExpanded ? "Show More" : "Show Less"}
          </span>
        </div>
      )}
    </div>
  );
};

export default NewInstructorList;
