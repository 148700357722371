import Image from "next/image";
import { ImageLeftProps } from "../types";
import { fixUrlSrc } from "@/utils/functions/fixUrlSrc";

export default function Headline({
  id,
  children,
  title,
  imageurl,
  imagetitle,
}: ImageLeftProps) {
  const image = fixUrlSrc(imageurl);

  return (
    <div id={id} className={`container py-4 ${id ? "scroll-m-20" : ""}`}>
      <h2 className="text-5xl">{title}</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="hidden lg:flex items-start justify-center">
          <Image
            className="clip-slant-x-both"
            src={image}
            alt={imagetitle || ""}
            width={466}
            height={316}
          />
        </div>
        <div className="prose-xl prose-a:text-link prose-a:underline hover:prose-a:text-primary">
          {children}
        </div>
      </div>
    </div>
  );
}
