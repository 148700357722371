"use client";
import * as React from "react";
import Image from "next/image";
import { InstructorListInstanceProps } from "../types";
import Link from "next/link";

const DefaultInstructorList = ({
  instructors,
  title,
}: InstructorListInstanceProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const displayedCards = isExpanded ? instructors : instructors?.slice(0, 3);
  const gridClasses = `
  w-full px-2 md:px-4 grid gap-3
  lg:grid-cols-${displayedCards.length > 3 ? 3 : displayedCards.length} lg:place-items-center
  md:grid-cols-${displayedCards.length > 2 ? 2 : displayedCards.length} 
  ${displayedCards.length === 2 ? "md:[&>*:nth-child(odd)]:justify-self-end md:[&>*:nth-child(even)]:justify-self-start" : "md:place-items-center"}
  sm:grid-cols-1
`;

  return (
    <div>
      {title ? (
        <div className="flex justify-center align-center mb-1">
          <h2 className="text-2xl text-primary">{title}</h2>
        </div>
      ) : null}
      <div className={gridClasses}>
        {displayedCards.map((card) => (
          <Link
            key={card.id}
            href={`/bio/${card.slug}`}
            className="w-full md:max-w-[399px] min-h-[292px] px-6 py-5 rounded-xl border border-solid hover:underline flex items-center justify-center bg-primary"
          >
            <div className="w-full h-full flex self-start text-center flex-col justify-center">
              {card.imageUrl ? (
                <div className="h-24 w-24 mx-auto">
                  <Image
                    src={card.imageUrl || ""}
                    alt={card.id || ""}
                    title={card.id}
                    width={96}
                    height={96}
                    layout="responsive"
                    className="max-w-[96px] max-h-[96px] md:max-w-[96px] md:max-h-[96px] m-0 md:m-auto rounded-full"
                  />
                </div>
              ) : null}
              <span className="flex items-center justify-center text-lg text-white font-bold mt-8 mb-4 min-h-[55px]">
                {card.firstName} {card.lastName}
              </span>
              <p className="text-base !text-white leading-7 h-20 line-clamp-3">
                {`${card.position || ""} • ${card?.organizationName}`}
              </p>
            </div>
          </Link>
        ))}
      </div>
      {(instructors?.length ?? 0) > 3 && (
        <div className="flex justify-center mt-5">
          <span
            className="underline cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </span>
        </div>
      )}
    </div>
  );
};

export default DefaultInstructorList;
